<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push(`/letters/${routeBack}/list`)"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import ModalToolMultiple from "@/view/components/general/ModalToolMultiple.vue";
import moment from "moment";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
    routeBack: String,
  },
  components: {
    MainForm,
    ModalToolMultiple,
  },
  data() {
    return {
      formData: new FormData(),
      menuDate1: false,
      error: {
        semester_id: "",
        title: "",
        observation_place: "",
        observation_purpose: "",
        tools: "",
        start_time: "",
        end_time: "",
        teacher_mentor: "",
      },
      start_time: new Date(),
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      toolCollection: [],
      toolCollectionForm: {
        id: "",
        name: "",
        amount: "",
      },
      toolCollectionError: {
        amount: "",
      },
      indexToolSelected: 0,
      toolPurpose: "add",
      teachers: [],
    };
  },
  methods: {
    chooseTool(data) {
      this.toolPurpose = "add";
      console.log("data", data);
      this.toolCollectionForm.name = data.name;
      this.toolCollectionForm.id = data.id;
      this.toolCollectionForm.amount = "";
      this.$bvModal.show("form-tool-value");
    },
    unCheckTool(value) {
      for (let a = 0; a < this.toolCollection.length; a++) {
        if (this.toolCollection[a].id == value.id) {
          this.toolCollection.splice(a, 1);
        }
      }
    },
    cancelModal(){
      this.$root.$emit('cancelModal', this.toolCollectionForm)
    },
    addTool(bvModalEvt) {
      if (this.toolCollectionForm.amount == "") {
        bvModalEvt.preventDefault();
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.toolCollectionError.amount = "Kolom jumlah wajib diisi";
        throw new Error("validation error");
      } else {
        this.toolCollectionError.amount = "";
      }

      if (this.toolPurpose == "add") {
        let clone = { ...this.toolCollectionForm };
        this.toolCollection.push(clone);
        this.$toast.success("Berhasil tambah alat");
        console.log("tool", this.toolCollection);
      } else {
        this.$toast.success("Berhasil edit jumlah alat");
        this.toolCollection[this.indexToolSelected].id =
          this.toolCollectionForm.id;
        this.toolCollection[this.indexToolSelected].name =
          this.toolCollectionForm.name;
        this.toolCollection[this.indexToolSelected].amount =
          this.toolCollectionForm.amount;
      }
      this.$bvModal.hide("form-tool-value");
    },
    deleteTool(index) {
      this.toolCollection.splice(index, 1);
    },
    editTool(i) {
      this.toolPurpose = "edit";
      this.indexToolSelected = i;

      this.toolCollectionForm.id = this.toolCollection[i].id;
      this.toolCollectionForm.name = this.toolCollection[i].name;
      this.toolCollectionForm.amount = this.toolCollection[i].amount;

      this.$bvModal.show("form-tool-value");
    },
    setStartTime(evt) {
      let start_time = moment(evt).format("YYYY-MM-DD HH:mm:ss");
      this.form.start_time = start_time;
    },
    async getTeacherOption() {
      let response = await module.setTreeSelect(
        "api/users?role_id=1&page_size=100"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },
    validationCheck() {
      let status = true;
      // if (this.purpose == "add") {
      //   if (
      //     this.form.hasOwnProperty("sign_image") == false ||
      //     this.form.sign_image == ""
      //   ) {
      //     this.error.sign_image = "Gambat TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue");
      //     console.log("send emit");
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse");
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
    //   if (this.validationCheck() == false) {
    //     Swal.fire({
    //       title: "Gagal",
    //       text: "Harap periksa kembali form",
    //       icon: "error",
    //       toast: true,
    //       position: "top-end",
    //       showConfirmButton: false,
    //       timer: 3000,
    //       timerProgressBar: true,
    //     });
    //     throw new Error("Validation Error");
    //   }

    //   this.form.tools = JSON.stringify(this.toolCollection)

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push(`/letters/${this.routeBack}/list`);
        console.log(this.routeBack)
      }
    },
  },
  mounted() {
    this.getTeacherOption();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;

        this.start_time = this.form.start_time
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>